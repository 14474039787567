.container {
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
}

.title_wrapper {
    margin-bottom: 4px;
}

.progress_container {
    width: 100%;
    position: relative;
}

.progress_wrapper {
    position: absolute;
    width: 100%;
}

.progress {
    height: 10px;
    border-radius: 3px;
    background-color: var(--primary-color);
}

.progress_mark {
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    color: var(--medium-color);
}

.mark {
    color: var(--dark-color);
    position: relative;
}

.marker_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: -39px;
    right: -10px;
}

.icon_container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--background-color);
    border: 1px solid var(--medium-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.baseline_wrapper {
    position: absolute;
    width: 100%;
}

.baseline {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background-color: rgba(128, 128, 128, 0.123);
}

.baseline_none {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background-color: var(--background-color);
}

.baseline_mark{
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--medium-color);
}
@import './styles/variables.module.css';

.App {
  text-align: center;
  background-color:var(--background-color);
  width: 100vw;
  height: 100vh;
  font-family: var(--main-font);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App_layout {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  overflow: hidden;
}


.left_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  
}

.logo_container {
  background-color: var(--light-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_container {
  height: 100%;
  width: 100%;
}

.layout_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  
}

.dimension_container {
  width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.page_container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.login_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_container::-webkit-scrollbar {
	height: 4px;
  width: 4px;
}

.page_container::-webkit-scrollbar-thumb{
	background-color: #aaaaaa47; 
	border-radius: 5px;
}


@media only screen and (min-width: 1060px) and (max-width: 1259px) {
  .App {
    scale: 100%;
  }

  .right_container {
    scale: 85%;
  }

  .left_container {
    scale: 85%;
  }
  
}

@media only screen and (min-width: 600px) and (max-width: 1059px) {
  .App {
    scale: 100%;
  }

  .right_container {
    scale: 75%;
  }

  .left_container {
    scale: 75%;
  }
  
}

/* For screens between 1025px and 1440px wide */
@media only screen and (min-width: 1260px) and (max-width: 1440px) {

  .left_container{
    height: 110%;
    scale: 85%;
    position: relative;
    top: -5%;
  }

  .right_container{
    height: 110%;
    scale: 85%;
    position: relative;
    top: -5%;
  }

}

/* For screens larger than 1440px wide */
@media only screen and (min-width: 1441px) {
  .App {  
    scale: 100%;
  }
  
}





.container {
    height: 35px;
    width: fit-content;
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--medium-color);
    cursor: pointer;
}

.active {
    background-color: var(--light-color);
    color: var(--dark-color) ;
}

.container:hover {
    transform: scale(1.1);
    background-color: var(--light-color);
    color: var(--dark-color) ;

}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.stats_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32%;
}

.card_light {
    width: 35%;
    height: fit-content;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 20px;
}


.top_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.title_container {
    font-weight: 700;
    font-size: 24px;
}

.action_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.button_wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--medium-color);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
}

.button_container {
    padding: 0px 17px;
}

.arrow_container {
    width: 30px;
}

.bottom_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.stat_data_container {
    display: flex;
    flex-direction: column;
    border-left: 3px solid var(--medium-color);
    text-align: left;
    font-weight: 700;
    font-size: 12;
    gap:10px;
    padding-left: 10px;
    
}

.title_wrapper {
    width: 120px;
    height: 60px;
    z-index: 2;
}

.stat_container {
    font-size: 24px;
    z-index: 2;
}

.design_wrapper {
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    overflow: hidden;
    padding-top: 10px;
    
}

.concentric_pattern {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: 40px;
    z-index: 1;
}

.concentric_outer {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background-color: var(--background-color);;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.concentric_center {
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-color: #F7F7F7;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.concentric_inner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FBFBFB;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}


.design_wrapper:hover > .concentric_pattern > .concentric_inner {
    transform: scale(1.2);
    transition-delay: 0.20s;
}

.design_wrapper:hover > .concentric_pattern > .concentric_center {
    transform: scale(1.2);
    transition-delay: 0.23s;
}

.design_wrapper:hover > .concentric_pattern > .concentric_outer {
    transform: scale(1.2);
    transition-delay: 0.26s;
}

.card_dark {
    width: 35%;
    height: fit-content;
    background-color: #202020;
    color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 20px;
}

.dark_top_wrap {
    margin-top: 45px;
}

.action_icon {
    opacity: 0.5;
}

.card_light_half {
    width: 18%;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    background-image: url('../../assets/icons/svg/grid.svg');
    padding: 20px 10px 22px 10px;
    background-size: cover; /* Adjust to your preference (cover, contain, etc.) */
    background-repeat: no-repeat;
    background-position: center center; /* Center the background */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card_light_half  {
    font-size: 20px;
    font-weight: 700;

}

 .card_bottom_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2 {
    background-color: var(--light-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    font-size: 16px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2_wrapper {
    background-color: var(--secondary-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    transform: rotate(6deg);
    margin-top: 10px;
}

.mid_container {
    position: sticky;
    top: -1px;
    background-color: var(--background-color);
    z-index: 3;
}

.search_container {
    width: 100%;
    height: 50px;
    background-color: var(--light-color);
    border-radius: 29px;
    display: flex;
    align-items: center;
    padding: 0px 9px;
    justify-content: space-between;
}


.searchbar_container {
    display: flex;
    align-items: center;
    width: 300px; /* Adjust as needed */
    background-color: var(--background-color);
    height: 35px;
    border-radius: 20px;
  }
  
  .search_input {
    flex: 1;
    height: 40px; /* Adjust as needed */
    padding: 10px;
    border: none;
    border-radius: 20px; /* Half of the height */
    outline: none; /* Remove default input focus outline */
    background-color: transparent;
    font-family: var(--main-font);
    font-size: 12px;
  }
  
  .search_icon {
    margin-right: 0px;
    margin-left: 12px;
  }

  .buttons_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .searchbar_button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .searchbar_button:hover {
    background-color: var(--secondary-color);
    transform: scale(1.1);
  }

  .filter_container {
    margin-top: 15px;
    display: flex;
    gap: 15px;
    background-color: var(--background-color);
    margin-bottom: 5px;
  }

  .filter_wrapper {
    height: 35px;
    width: fit-content;
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--medium-color);
    cursor: pointer;
    background-color: var(--light-color);
}

.filter_wrapper img {
    margin-right: 8px;
}

.listing_container {
    width: 100%;
}

.card_tile_container {
    margin-top: 20px;
}

.text_div {
    color: var(--medium-color);
    font-size: 14px;
}
  
/* Checkbox.css */
.container {
    cursor: pointer;
  }
  
  .container input {
    display: none;
  }
  
  .container svg {
    overflow: visible;
  }
  
  .path {
    fill: none;
    stroke: rgb(0, 0, 0);
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
  }
  
  .container input:checked ~ svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
    stroke-width: 6;
  }
  
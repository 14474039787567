.card {
    height: 100%;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    text-align: left;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .skills_container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 12px;
    height: 70%;
    width: 100%;
  }
  
  .skill_item {
    max-width: 75%;
    border: 1px solid var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 5px 20px;
    height: 16px;
  }

  .text_wrap {
    padding: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}
  
  .remaining_skills {
    border: 1px solid var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 5px 20px;
    height: 16px;
  }
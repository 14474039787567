.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.organizations_list {
    width: 50%;
    padding: 25px;
    border-right: 1px solid var(--medium-color);
}

.logo {
    width: 100%; /* Make the image take up 100% of the container's width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block;
}

.logo_wrapper {
    width: 150px;
}

.name_wrapper {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.carousel {
display: flex;
width: 80%;
align-items: center;
justify-content: space-around;
}

.carousel_button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-color);
    cursor: pointer;
}

.carousel_button:hover {
    opacity: 80%;
}

.invert {
    transform: rotate(180deg);
}

.organization_card {
border: 1px solid #ddd;
border-radius: 20px;
padding: 20px;
display: flex;
align-items: center;
gap: 25px;
cursor: pointer;
width: 80%;

}

.organization_card:hover {
    border: none;
    border-radius: 20px;
    background-color: var(--primary-color);
    scale: 1.01;
    
    }

.sub_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.create_organization {
width: 50%;
display: flex;
justify-content: center;
align-items: center;
gap:15px;
border-left: 1px solid var(--medium-color);

}

.button_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    background-color: var(--dark-color);
    padding: 15px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
    width: 200px;
    height: 48px;
    border: none;

}

.button_1:hover {
    background-color: var(--secondary-color);
    color: var(--dark-color);
}
.container {
    width: 100%;
    background-color: var(--secondary-color);
    padding: 25px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    height: 260px;
}

.left_container {
    overflow: hidden;
    background-color: #202020;
    width: 160px;
    border-radius: var(--border-radius);
    color: var(--light-color);
    text-align: left;
    padding: 12px 15px 10px 15px;
    cursor: pointer;
    height: 100%;
}

.data_container { 
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.data_title {
    z-index: 2;
    font-weight: 700;
    font-size: 18px;
    position: relative;
}

.data_value {
    z-index: 2;
    font-weight: 400;
    font-size: 16px;
    position: relative;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.data_value_1 {
    z-index: 2;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    display: flex;
}

.concentric_pattern {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 40px;
    z-index: 1;
}

.concentric_outer {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-color: var(--background-color);;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 4px 0px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
    background-color: #2D2D2D;
}

.concentric_center {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #373737;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 4px 0px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.concentric_inner {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background-color: #343434;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 4px 0px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.left_container:hover > .concentric_pattern > .concentric_inner {
    transform: scale(1.2);
    transition-delay: 0.20s;
}

.left_container:hover > .concentric_pattern > .concentric_center {
    transform: scale(1.2);
    transition-delay: 0.23s;
}

.left_container:hover > .concentric_pattern > .concentric_outer {
    transform: scale(1.2);
    transition-delay: 0.26s;
}

.mid_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    width: 395px;
    height: 100%;
}

.mid_top_name_container {
    display: flex;
    height: 50px;
    width: 100%;
    padding: 25px;
    align-items: center;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
}

.mid_top_name_data {
    display: flex;
    border-left: 2px solid var(--medium-color);
    align-items: center;
    gap: 15px;
    padding: 0px 10px 0px 15px;
}

.mid_top_container {
    display: flex;
    height: 100px;
    width: 100%;
    padding: 25px;
    justify-content: space-around;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
}

.mid_top_data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-left: 2px solid var(--medium-color);
    padding: 0px 10px 0px 15px;
    width: 50%;
}

.mid_bottom_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.mid_tile_container {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    width: 180px;
}

.mid_tile {
    background-color: var(--light-color);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    width: 120px;
}

.text_wrap {
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.mid_icon {
    width: 50px;
}

.right_container {
    height: 100%;
    display: flex;
    width: 450px;
    justify-content: space-between;
}

.sub_container_1 {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
}

.experience_container {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
}

.experience_tile {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-left: 20px;
    padding-right: 15px;
}

.skill_container {
    flex-grow: 1;
}

.sub_container_2 {
    width: 175px;
    display: flex;
    flex-direction: column;
    height: 100%;
}



.buttons_container {
    padding: 15px;
    height: 50px;
    border-radius: var(--border-radius);
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 175px;
    margin-bottom: 10px;
}

.demand_buttons {
    height: 35px;
    width: 35px;
    background-color: var(--light-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.demand_buttons:hover {
    transform: scale(1.1);
    border: 1px solid var(--medium-color);
}

.progress_card {
    background-color: var(--light-color);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    padding: 15px;
    border-radius: var(--border-radius);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.progress_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progress_title {
    width: 50%;
}

.progress_icon {
    height: 40px;
    width: 40px;
    background-color: var(--background-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress {
    font-size: 50px;
    font-weight: 500;
}

.stages_filler {
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
}

.root_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_container {
    display: flex;
    width: 35%;
    background-image: url('../../assets/icons/svg/modalgrid.svg');
    background-size: contain; /* Adjust to your preference (cover, contain, etc.) */
    background-position: center center;  
    justify-content: space-around;
    padding: 20px;
    flex-direction: column;
    font-family: var(--main-font);
    background-color: var(--light-color);
}

.form_label {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 14px;
}

.title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 15px;
}

.line_2 {
    background-color: var(--light-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    font-size: 16px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2_wrapper {
    background-color: var(--secondary-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    transform: rotate(6deg);
    margin-top: 5px;
}

.login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.username_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.password_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.text {
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 35px;
    width: 100%;
}

.button_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    background-color: var(--dark-color);
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
    height: 38px;
    border: none;
}

.button_txt {
    width: 100px;
}

.button_1:hover {
    background-color: var(--secondary-color);
    color: var(--dark-color);
}

.error_container {
    font-weight: 400;
    font-size: 12px;
    color: red; 
    height: 20px;
    margin-top: 3px;
    margin-bottom: 5px;
}

.signin_link {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .signin_link span {
    color: #3498db;
    text-decoration: none;
    cursor: pointer;
  }
  
  .signin_link span:hover {
    text-decoration: underline;
  }

  @media only screen and (min-width: 1260px) and (max-width: 1440px) {
    /* .form_container{
        scale: 85%;
    } */
  }

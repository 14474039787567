.container {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.default_container {
    text-align: left;
    background-color: var(--light-color);
    padding: 25px;
    border-radius: 5px;
    border: 1px solid var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.info {
    font-size: 16px;
}

.progress {
    margin-bottom: 30px;
}


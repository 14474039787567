.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 29px;
    justify-content: space-between;
}

.left_section {
    display: flex;
    align-items: center;
    gap: 15px;
}

.title_container {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    gap: 15px;
}

.divider {
    width: 87px;
    height: 5px;
    border-radius: 3.5px;
    background-color: var(--dark-color);
}


.status_container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button_primary {
    height: 35px;
    width: 125px;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var( --primary-color);
    font-weight: 700;
    font-size: 12px;
    gap: 5px;
    cursor: pointer;
}

.right_section {
    display: flex;
    gap: 12px;
    align-items: center;
}

.user_container {
    height: 35px;
    display: flex;
    background-color: var(--light-color);
    gap:10px;
    align-items: center;
    padding: 0px 5px;
    border-radius: var(--border-radius);
}

.user_avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-color);
}

.user_details {
    font-weight: 700;
    font-size: 12px;
    color: var(--dark-color);
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    
}

.user_role {
    font-weight: 400;
    font-size: 12px;
    color: var(--medium-color);
}

.plan_wrapper {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan_container {
    height: 25px;
    width: 70px;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var( --secondary-color);
    font-weight: 500;
    font-size: 10px;
    gap: 5px;
    cursor: pointer;
}
.container {
    display: flex;
    height: 100vh;
    border-radius: 22px;
    background-color: var(--light-color);    
    padding: 20px 0px;
}

.modal_wrapper {
    width: 100%;
}


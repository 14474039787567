.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.stats_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32%;
}

.card_light_half {
    width: 200px;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    background-image: url('../../assets/icons/svg/grid.svg');
    padding: 20px 10px 22px 10px;
    background-size: cover; /* Adjust to your preference (cover, contain, etc.) */
    background-repeat: no-repeat;
    background-position: center center; /* Center the background */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card_light_half  {
    font-size: 20px;
    font-weight: 700;

}

 .card_bottom_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2 {
    background-color: var(--light-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    font-size: 16px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2_wrapper {
    background-color: var(--secondary-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    transform: rotate(6deg);
    margin-top: 10px;
}

.mid_container {
    position: sticky;
    top: -1px;
    background-color: var(--background-color);
    z-index: 3;
}

.search_container {
    width: 100%;
    height: 50px;
    background-color: var(--light-color);
    border-radius: 29px;
    display: flex;
    align-items: center;
    padding: 0px 9px;
    justify-content: space-between;
}




.buttons_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .searchbar_button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .searchbar_button:hover {
    background-color: var(--secondary-color);
    transform: scale(1.1);
  }

  .filter_container {
    margin-top: 15px;
    display: flex;
    gap: 15px;
    background-color: var(--background-color);
    margin-bottom: 5px;
  }

  .filter_wrapper {
    height: 35px;
    width: fit-content;
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--medium-color);
    cursor: pointer;
    background-color: var(--light-color);
}

.filter_wrapper img {
    margin-right: 8px;
}

.listing_container {
    width: 100%;
}

.card_tile_container {
    margin-top: 20px;
}

.text_div {
    color: var(--medium-color);
    font-size: 14px;
}
  
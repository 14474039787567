.container {
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.container_1 {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}


.input_field {
    font-size: 14px;
    font-family: var(--main-font); 
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
}

.textarea {
    height: 120px;
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
}

.textarea::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.textarea::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.input_field_1 {
    font-size: 14px;
    font-family: var(--main-font); 
    background-color: var(--background-color);
    border-radius: 5px;
    border: 1px solid #ECECEC;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
    width: 100%;
}

.input_field_half {
    font-size: 14px;
    font-family: var(--main-font); 
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
    width: 35%;
    cursor: pointer;
}

.input_field:focus {
    border-color: transparent;
    outline: none;
}

.input_field_1:focus {
    border-color: transparent;
    outline: none;
}

.input_field_half:focus {
    border-color: transparent;
    outline: none;
}

.tags_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    padding: 10px 0px ;
}

.tag {
    border: 1px solid var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 5px 20px;
    height: 16px;
    font-size: 12px;
}

.cancel_button {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--light-color);
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 8px;
}

.suggestions_dropdown {
    position: absolute;
    text-align: center;
    background-color: var(--light-color);
    z-index: 4;
    top:110%;
    max-height: 150px;
    width: 100%;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.suggestions_dropdown::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.suggestions_dropdown::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.suggestion_item:hover {
    background-color: var(--primary-color);
    cursor: pointer;

}

.field_add {
    cursor: pointer;
}

.field_label_1 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sub_container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: var(--background-color);
}

.top_container {
    width: 100%;
    background-color: var(--background-color);
}

.top_container_1 {
    width: 100%;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.field_container {
    width: 100%;
    margin-bottom: 5px;
}

.bottom_container {
    display: flex;
    align-items: center;
    padding-right: 10px; 
    gap: 15px;
    
}

.close {
    transform: rotate(45Deg);
    position: relative;
    top: 1px;
}

.close_wrap {
    cursor: pointer;
}

.icon {
    height: 20px;
    width: 20px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.accordion {
  width: 32%;
  background-color: var( --light-color); 
  height: 560px;
}

.cardList_container {
  width: 64%;
  height: 560px;
  overflow-y: scroll;
  padding: 5px;
}



.category {
  margin-bottom: 5px;
}

.categoryTitle {
  width: 100%;
  background-color: #000000;
  border: none;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  font-family: var(--main-font);
  font-weight: 600;
  height: 50px;
  align-items: center;
  border: 1px solid var(--medium-color);
}

.categoryTitleBut {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  color: black;
  border: 1px solid var(--medium-color);
  border-radius: 5px;
  cursor: pointer;
}

.categoryTitle:hover {
  background-color: var( --background-color);
  color: black;
}

.categoryTitle:hover .categoryTitleBut {
  background-color: #000000;
  color: white;
  
}

.categoryContent {
  padding: 10px;
  border-top: none;
  height: 400px;
  overflow-y: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid var(--medium-color);
  background-color: var(--background-color);
  padding: 8px;
  text-align: left;
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  min-width: 150px;    /* Set a fixed width for table cells */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.table td:hover {
  background-color: var(--primary-color);
  font-weight: 600;
}

.table th {
  background-color: #f2f2f2;
}

.categoryContent::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.categoryContent::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.cardList_container::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.cardList_container::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}



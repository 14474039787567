.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 90%;
    border-radius: 29px;
    background-color: var(--light-color);    
    padding: 20px 0px;
}

.top_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    
}

.bottom_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

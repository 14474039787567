.container {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
}

.top_container {
    background-color: var(--light-color);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.top_container button { 
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: black;
  border: 1px solid var(--medium-color);
  border-radius: 5px;
  cursor: pointer;
}

.top_container button:hover { 
    background-color: var(--secondary-color);
  }

.bottom_container {
    
}


.form_field {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    color: #535353;
}

.field_label {
    margin-bottom: 10px;
}



.text {
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
}

.textarea {
    height: 120px;
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
}

.textarea::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.textarea::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.date {
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
}



.select {
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    height: 40px;
}

.error_container {
    font-weight: 400;
    font-size: 12px;
    color: red; 
    height: 20px;
    margin-top: 3px;
    margin-bottom: 5px;
}


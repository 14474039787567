.searchbar_container {
    display: flex;
    align-items: center;
    width: 300px; /* Adjust as needed */
    background-color: var(--background-color);
    height: 35px;
    border-radius: 20px;
  }
  
  .search_input {
    flex: 1;
    height: 40px; /* Adjust as needed */
    padding: 10px;
    border: none;
    border-radius: 20px; /* Half of the height */
    outline: none; /* Remove default input focus outline */
    background-color: transparent;
    font-family: var(--main-font);
    font-size: 12px;
  }
  
  .search_icon {
    margin-right: 0px;
    margin-left: 12px;
  }
.container {
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.stat_text {
    color: var(--medium-color);
    font-weight: 700;
    font-size: 10px;
    text-align: left;
    width: 80px;
}

.graph_container {
    width: 110px;
    height: 100px;
    position: relative;
}

.base {
    width: 100%;
    height: 100%;
    background-color: #2D2D2D;
    border-radius: 6px;
    position: absolute;
}

.graph {
    width: 100%;
    border-radius:  6px;
    position: absolute;
    bottom: 0px;
}

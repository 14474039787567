.container {
    width: 100%;
    background-color: var(--secondary-color);
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    height: 80px;
}

.left_container {
    background-color: #202020;
    border-radius: var(--border-radius);
    color: var(--light-color);
    text-align: left;
    cursor: pointer;
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center; 
    justify-content: center;
    padding-left: 25px;
}


.data_progress { 
    height: 10px;
    width: 100%;
}

.data {
    width: 150px;
    max-width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.data_title {
    z-index: 2;
    font-weight: 700;
    font-size: 16px;
}

.data_value {
    z-index: 2;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mid_container {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-around;
    width: 760px;
}

.sub_container_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 250px;
}

.sub_container_2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.right_container {
    height: 50px;
    display: flex;
    width: 175px;
    align-items: center;
    justify-content: center;
}


.buttons_container {
    padding: 15px;
    height: 50px;
    border-radius: var(--border-radius);
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 175px;
}

.demand_buttons {
    height: 35px;
    width: 35px;
    background-color: var(--light-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.demand_buttons:hover {
    transform: scale(1.1);
    border: 1px solid var(--medium-color);
}
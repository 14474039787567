.icon_wrapper {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.active {
    background-color: var(--primary-light-color);
}

.icon_wrapper:hover {
    background-color: var(--primary-light-color);
}

.icon_wrapper:hover > .icon {
    transform: scale(1.1);
}
.container {
    border: 1px solid var(--light-color);
    background-color: var(--light-color);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    font-size: 12px;
}

.checkbox_wrapper {
    width: 5%;
    padding: 15px;
}
.data_wrapper {
    display: flex;
}

.name_wrapper {
    font-size: 15px;
    font-weight: 500;
}

.iconData {
    font-size: 11px;
    display: flex;
    gap: 5px;
    margin-right: 5px;
}

.profile_container {
    border-left: 2px solid var(--background-color);
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px ;
}

.info_container {
    width: 55%;
    padding: 15px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.activity {
    padding: 2px;
    border: 1px solid var(--medium-color);
    font-size: 11px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.updated {
    font-size: 10px;
    margin-bottom: 5px;

}

.profile_description {
    font-size: 11px;
    margin-bottom: 5px;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
}

.profile_description_full {
    white-space: normal;
    display: block; /* Ensure full text is shown */
  }

.data_label {
    color: var(--medium-color);
    font-weight: 400;
    font-size: 11px;
    padding-bottom: 3px;
    vertical-align: top; /* Align label to the top */
    text-align: left;
    min-width: 80px;
}

.data_value {
    font-weight: 400;
    font-size: 11px;
    padding-left: 20px;
    max-width: 250px;
    vertical-align: top; /* Align label to the top */
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    cursor: pointer;
  }
  
  .data_value_full {
    white-space: normal;
    display: block; /* Ensure full text is shown */
  }
  

.spacer {
    margin-bottom: 5px;
}



.image_wrapper img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.image_wrapper {
    margin-bottom: 5px;
}

.tab_container {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 2px solid var(--background-color);
}

.but {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: fit-content;
}


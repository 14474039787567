@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Fonts */
:root {
    --main-font: 'Urbanist', sans-serif;
  }
  
  /* Colors */
  :root {
    --primary-color: #CEADF8;
    --primary-light-color: #dec9f9;
    --secondary-color: #E2F3A2;
    --background-color: #F4F4F4;
    --dark-color: #000000;
    --medium-color: #979797;
    --light-color: #FFFFFF;
    
    --link-color: #007bff;
    --link-hover-color: #0056b3;
    --success-color: #28a745;
    --error-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
  }
  
  /* Spacing */
  :root {
    --spacing-unit: 8px;
    --padding-small: var(--spacing-unit);
    --padding-medium: calc(2 * var(--spacing-unit));
    --padding-large: calc(3 * var(--spacing-unit));
  }
  
  /* Border */
  :root {
    --border-radius: 20px;
    --border-width: 1px;
    --border-color: #D9D9D9;
  }
  
  /* Box Shadow */
  :root {
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Breakpoints */
  :root {
    --sm-screen: 576px;
    --md-screen: 768px;
    --lg-screen: 992px;
    --xl-screen: 1200px;
  }
  
  /* Z-index */
  :root {
    --z-index-modal: 1000;
    --z-index-tooltip: 1100;
    --z-index-navbar: 1030;
    --z-index-dropdown: 1000;
    --z-index-sticky: 1020;
  }
  
  /* Media Queries */
  :root {
    --mobile: @media (max-width: 767px);
    --tablet: @media (min-width: 768px) and (max-width: 991px);
    --desktop: @media (min-width: 992px) and (max-width: 1199px);
    --large-desktop: @media (min-width: 1200px);
  }
  
.card_dark {
    height: 250px;
    width: 400px;
    background-color: #202020;
    color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 20px;
}

.dark_top_wrap {
    margin-top: 7px;
}

.action_icon {
    opacity: 0.5;
}

.top_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title_container {
    font-weight: 700;
    font-size: 24px;
}

.action_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(240, 248, 255, 0.148);
    cursor: pointer;
}

.action_container:hover {
    transform: scale(1.1);
    background-color: rgba(240, 248, 255, 0.255);
}

.bottom_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



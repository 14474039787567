.card_light {
    width: 400px;
    height: 250px;
    background-color: var(--light-color);
    border-radius: var(--border-radius);
    padding: 20px;
}


.top_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.title_container {
    font-weight: 700;
    font-size: 24px;
}

.action_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.button_wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--medium-color);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    width: fit-content;
}

.button_container {
    padding: 0px 17px;
}

.arrow_container {
    width: 30px;
}

.bottom_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.stat_data_container {
    display: flex;
    flex-direction: column;
    border-left: 3px solid var(--medium-color);
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    gap:10px;
    padding-left: 10px;
    width: 110px;
}

.title_wrapper {
    height: 38px;
    z-index: 2;
}

.stat_container {
    font-size: 22px;
    z-index: 2;
}

.design_wrapper {
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    overflow: hidden;
    padding-top: 10px;
    
}

.concentric_pattern {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: 40px;
    z-index: 1;
}

.concentric_outer {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background-color: var(--background-color);;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.concentric_center {
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-color: #F7F7F7;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.concentric_inner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FBFBFB;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    position: absolute;
    transition: transform 0.3s ease-in-out;
}


.design_wrapper:hover > .concentric_pattern > .concentric_inner {
    transform: scale(1.2);
    transition-delay: 0.20s;
}

.design_wrapper:hover > .concentric_pattern > .concentric_center {
    transform: scale(1.2);
    transition-delay: 0.23s;
}

.design_wrapper:hover > .concentric_pattern > .concentric_outer {
    transform: scale(1.2);
    transition-delay: 0.26s;
}